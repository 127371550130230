@import "../shared/shared";

.league-table {
    font-family: $fixed_width_font;

    th {
        text-align: left;
    }
    th, td {
        padding-right: 16px;
    }
}
