@import "../shared/shared";

.editor-window {
    flex-grow: 1;
    position: relative;
}

.editor {
    display: flex;
    flex-direction: column;
    height: 100%;

    .editor-title {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 10px;
        border-bottom: 2px solid cornflowerblue;

        .label-filename {
            font-family: $fixed_width_font;
            font-size: 20px;
            font-weight: bold;
            color: steelblue;
            padding: 3px;
        }

        input {
            font-family: $fixed_width_font;
            padding: 3px;
            border-radius: 4px;
            border: 1px solid lightgrey;
            width: 250px;
            margin: 0 5px;
        }
        input.duplicate-file-name {
            border-color: indianred;
            outline: none;
        }

        .duplicate-file-name-warning {
            font-family: $fixed_width_font;
            font-size: 17px;
            font-weight: bold;
            color: indianred;
            padding: 5px;
        }

        .spacer {
            flex-grow: 1;
            display: flex;
            justify-content: flex-end;
        }

        .delete-file {
            background: none;
            border: 2px solid indianred;
            font-family: $fixed_width_font;
            color: darkred;
            cursor: pointer;
        }
        .delete-file:hover {
            background-color: indianred;
            border-color: darkred;
            color: white;
        }
    }

    .editor-pane {
        flex-grow: 1;
        position: relative;
    }

}
.editor.hidden {
    display: none;
}
