@import "../shared/shared";

.game {
    h3 {
        font-family: $fixed_width_font;
        font-size: 16px;
        font-weight: bold;
        margin: 10px 0;
    }

    a {
        margin-left: 12px;
        color: cornflowerblue;
        text-decoration: none;

        span {
            border-bottom: 1px solid cornflowerblue;
        }
    }
    a:visited {
        color: cornflowerblue;
    }

    table {
        margin-left: 32px;
    }
    th {
        text-align: left;
    }
    th, td {
        font-family: $fixed_width_font;
        padding-right: 16px;
    }
}
