@import 'app';
@import 'components/cut-the-mustard-failed';

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.react-root {
  width: 100%;
  height: 100%;
}