@import "../shared/shared";
@import "league-table";
@import "game";

.match-runner {
    padding: 10px 13px;

    .match-runner-title {
        font-family: $fixed_width_font;
        font-size: 20px;
        font-weight: bold;
        color: indianred;
    }

    .match-runner-body-text {
        font-family: $fixed_width_font;
        color: dimgrey;
        padding: 15px 0;
    }

}
