@import "components/app/instructions";
@import "components/app/sample-opponents";
@import "components/app/navigation";
@import "components/app/editor-windows";
@import "components/runner/match-runner";

.app {
    height: 100%;

    .editors {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;


        iframe {
            width: 100%;
            height: 99%;
            border: none;
        }

    }
}
