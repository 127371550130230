@import "../shared/shared";

.file-list-green {
    border-right-color: forestgreen;
}
.file-list-blue {
    border-right-color: cornflowerblue;
}
.file-list-red {
    border-right-color: indianred;
}

.file-list {
    min-width: 275px;
    height: 100%;

    display: flex;
    flex-direction: column;
    flex-grow: 0;
    align-items: flex-end;

    padding-top: 50px;
    box-sizing: border-box;
    border-right-width: 2px;
    border-right-style: solid;


    button {
        margin: 5px 0 5px 5px;
        padding: 5px;

        font-family: $fixed_width_font;
        font-size: 16px;

        background-color: transparent;
        border-width: 2px;
        border-style: solid;
        border-right: none;

        cursor: pointer;
    }
    button:focus {
        outline:0;
    }

    button.green {
        border-color: yellowgreen;
    }
    button.green:hover, button.green.selected {
        border-color: forestgreen;
        background-color: yellowgreen;
    }

    button.blue {
        border-color: lightblue;
    }
    button.blue:hover, button.blue.selected {
        border-color: cornflowerblue;
        background-color: lightskyblue;
    }

    button.red {
        border-color: indianred;
    }
    button.red:hover, button.red.selected {
        border-color: darkred;
        background-color: indianred;
        color: white;
    }

    .gap {
        height: 35px;
    }
}
