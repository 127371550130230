@import "../shared/shared";

.instructions {
    display: flex;
    flex-direction: column;
    height: 100%;

    .instructions-title {
        padding: 10px 0 0 13px;
        font-family: $fixed_width_font;
        font-size: 20px;
        font-weight: bold;
        color: forestgreen;
    }

    .instructions-scrollable {
        overflow-y: scroll;
        padding: 0 0 40px 13px;

        h3 {
            font-family: "Courier New", Courier, monospace;
            font-size: 18px;
            font-weight: bold;
            color: forestgreen;
            margin: 1em 0;
            border-bottom: 1px solid forestgreen;
            width: fit-content;
        }

        p {
            font-family: $fixed_width_font;
            color: dimgrey;
        }

        .instructions-editor {
            padding-left: 15px;
        }
    }

}
