
.cut-the-mustard-failed {
    display: flex;
    justify-content: center;

    .ctm-centre-block {
        padding: 20px;
        max-width: 530px;
        text-align: center;

        .ctm-failed-title {
            font-family: "Courier New", Courier, monospace;
            font-size: 64px;
            font-weight: bold;
            color: indianred;
        }

        .ctm-failed-sad-face {
            font-family: "Courier New", Courier, monospace;
            font-size: 128px;
            font-weight: bold;
            color: darkslategrey;
            padding-top: 20px;
        }

        .ctm-failed-oh-no {
            font-family: "Courier New", Courier, monospace;
            font-size: 32px;
            font-weight: bold;
            color: dimgrey;
            padding-top: 40px;
        }
        .ctm-failed-explanation {
            font-family: "Courier New", Courier, monospace;
            font-size: 20px;
            color: dimgrey;
            padding-top: 16px;
        }
        .ctm-failed-explanation-detail {
            font-family: "Courier New", Courier, monospace;
            font-size: 16px;
            color: dimgrey;
            padding-top: 16px;
        }
    }
}
